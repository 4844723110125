import { createContext } from 'react';

export const GlobalContext = createContext();

export const initialState = {
    flashMessage: {},
    token: localStorage.token || ''
};

export function reducer(draft, action) {
    switch (action.type) {
        case 'setToken':
            draft.token = action.data.token
            break
        case 'addFlashMessage':
            draft.flashMessage = action.data;
            break;
        default:
            throw Error('Unknown action type ' + action.type)
    }
}

export default GlobalContext;
