import React from 'react'
import {
    initialState,
    reducer,
    TranslationsContext,
} from './Translations/TranslationContext'
import {useImmerReducer} from 'use-immer'
import TranslationsListing from './Translations/TranslationsListing'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import TranslationEdit from './Translations/TranslationEdit'

function Translations() {
    const [state, dispatch] = useImmerReducer(reducer, initialState)
    return (
        <TranslationsContext.Provider value={[state, dispatch]}>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/translations'>
                        <TranslationsListing />
                    </Route>
                    <Route exact path='/translations/edit/:id'>
                        <TranslationEdit />
                    </Route>
                </Switch>
            </BrowserRouter>
        </TranslationsContext.Provider>
    )
}

export default Translations
