import {createContext, useEffect} from 'react'

export const TranslationsContext = createContext()

export const initialState = {
    pager: {
        pageSize: 25,
        page: 1,
        totalCount: 0,
    },
    filter: localStorage.getItem('translations_filter')
        ? JSON.parse(localStorage.getItem('translations_filter'))
        : {
              brand: '',
              value: '',
              type: '',
              empty: '',
          },
    fetchTranslationsCount: 0,
    translations: [],
}

export function reducer(draft, action) {
    switch (action.type) {
        case 'fetchTranslations':
            draft.fetchTranslationsCount++
            break
        case 'changePage':
            draft.pager.page = action.data
            draft.fetchTranslationsCount++
            break
        case 'changeRowsPerPage':
            draft.pager.page = 1
            draft.pager.pageSize = action.data
            draft.fetchTranslationsCount++
            break
        case 'updateTranslations':
            draft.translations = action.data
            break
        case 'updateTranslationsPager':
            draft.pager.page = action.data.page
            draft.pager.totalCount = action.data.total
            break
        case 'filterBrand':
            draft.filter.brand = action.data
            break
        case 'filterValue':
            draft.filter.value = action.data
            break
        case 'filterType':
            draft.filter.type = action.data
            break
        case 'filterEmpty':
            draft.filter.empty = action.data
            break
        default:
            throw Error('Unknown action type ' + action.type)
    }
}

export default TranslationsContext
