import React, {useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import GlobalContext from "../GlobalContext";

function Header() {
    const [appState, appDispatch] = useContext(GlobalContext)
    const history = useHistory();
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-3">
            <div className="container-fluid">
                <span className="navbar-brand">Navbar</span>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className='nav-link' to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            { appState.token && <Link className='nav-link' to="/translations">Translations</Link> }
                        </li>
                        <li className="nav-item">
                            { appState.token && <span
                                onClick={() => {
                                    appDispatch({type: 'setToken', data: {token: ''}})
                                    history.push('/');
                                }}
                                className="nav-link" tabIndex="-1"
                            >Logout</span> }
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;
