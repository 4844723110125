import React, {useContext} from "react";
import Login from "./Login";
import GlobalContext from "../GlobalContext";

function Home() {
    const [appState] = useContext(GlobalContext);

    return (
        <div>
            { !appState.token && <Login />}
            {
                appState.token &&
                <div>
                    <h1>Hello</h1>
                </div>
            }
        </div>
    );
}

export default Home
