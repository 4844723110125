import React, {useContext, useEffect} from 'react'
import TranslationContext from './TranslationContext'
import TranslationsFilter from './TranslationsFilter'
import {Link} from 'react-router-dom'
import {TablePagination} from '@material-ui/core'
import Axios from 'axios'
import GlobalContext from '../../GlobalContext'
export default function TranslationsListing() {
    const [state, dispatch] = useContext(TranslationContext)
    const [, appDispatch] = useContext(GlobalContext)

    function handleChangePage(e, newPage) {
        dispatch({type: 'changePage', data: newPage + 1})
    }

    function handleChangeRowsPerPage(e) {
        dispatch({type: 'changeRowsPerPage', data: e.target.value})
    }

    useEffect(() => {
        let query = []
        for (const filter in state.filter) {
            if (state.filter.hasOwnProperty(filter)) {
                query.push(`${filter}=${state.filter[filter]}`)
            }
        }
        query.push(`page=${state.pager.page}`)
        query.push(`limit=${state.pager.pageSize}`)

        Axios.get('/translation?' + query.join('&'))
            .catch((e) => {
                appDispatch({
                    type: 'addFlashMessage',
                    data: {
                        type: 'error',
                        text: 'Error while fetching translations try logout & login again to refresh token',
                    },
                })
            })
            .then((res) => {
                if (res && res.status === 200 && res.data) {
                    dispatch({type: 'updateTranslations', data: res.data.data})
                    dispatch({
                        type: 'updateTranslationsPager',
                        data: res.data,
                    })
                }
            })
    }, [state.fetchTranslationsCount])

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <TranslationsFilter />
                </div>
            </div>

            <table className='table table-striped mt-4'>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Main Value</th>
                        <th>Translations</th>
                    </tr>
                </thead>
                <tbody>
                    {state.translations.map((translation, index) => {
                        return (
                            <tr key={index}>
                                <td>{translation.type}</td>
                                <td>
                                    <Link
                                        to={`/translations/edit/${translation.id}`}
                                    >
                                        {translation.value}
                                    </Link>
                                </td>
                                <td>
                                    <pre>
                                        {JSON.stringify(
                                            translation.translations,
                                            null,
                                            2
                                        )
                                            .replace('{\n', '')
                                            .replace('}', '')}
                                    </pre>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <TablePagination
                component='div'
                count={state.pager.totalCount}
                page={state.pager.page - 1}
                onChangePage={handleChangePage}
                rowsPerPage={state.pager.pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}
