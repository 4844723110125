import React, {useContext, useEffect} from 'react'
import ConfigService from '../../ConfigService'
import TranslationContext from './TranslationContext'

export default function TranslationsFilter() {
    const [state, dispatch] = useContext(TranslationContext)

    useEffect(() => {
        localStorage.setItem(
            'translations_filter',
            JSON.stringify(state.filter)
        )
    }, [state.filter])

    function handleSubmit(e) {
        e.preventDefault()
        dispatch({type: 'fetchTranslations'})
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='row'>
                <div className='form-group col-md-2'>
                    <label htmlFor='brand'>Brand</label>
                    <select
                        onChange={(e) => {
                            console.log(e.target.value)
                            dispatch({
                                type: 'filterBrand',
                                data: e.target.value,
                            })
                        }}
                        value={state.filter.brand}
                        className='form-control'
                        id='brand'
                    >
                        <option value=''>--- Choose ---</option>
                        {ConfigService.allowedBrands().map((brand, index) => {
                            return (
                                <option key={index} value={brand}>
                                    {brand}
                                </option>
                            )
                        })}
                    </select>
                </div>

                <div className='form-group col-md-2'>
                    <label htmlFor='main_value'>Main Value</label>
                    <input
                        onChange={(e) => {
                            dispatch({
                                type: 'filterValue',
                                data: e.target.value,
                            })
                        }}
                        value={state.filter.value}
                        type='text'
                        className='form-control'
                        id='main_value'
                        placeholder='Main Value'
                    />
                </div>

                <div className='form-group col-md-2'>
                    <label htmlFor='translation_type'>Translation type</label>
                    <select
                        onChange={(e) => {
                            dispatch({
                                type: 'filterType',
                                data: e.target.value,
                            })
                        }}
                        value={state.filter.type}
                        className='form-control'
                        id='translation_type'
                    >
                        <option value=''>--- Choose ---</option>
                        {ConfigService.allowedTranslationTypes().map(
                            (type, index) => {
                                return (
                                    <option key={index} value={type}>
                                        {type}
                                    </option>
                                )
                            }
                        )}
                    </select>
                </div>

                <div className='form-group col-md-2'>
                    <label htmlFor='empty_translation'>Empty translation</label>
                    <select
                        id='empty_translation'
                        onChange={(e) => {
                            dispatch({
                                type: 'filterEmpty',
                                data: e.target.value,
                            })
                        }}
                        value={state.filter.empty}
                        className='form-control'
                    >
                        <option value=''>--- Choose ---</option>
                        {ConfigService.allowedLanguages().map((lang, index) => {
                            return (
                                <option key={index} value={lang}>
                                    {lang}
                                </option>
                            )
                        })}
                    </select>
                </div>

                <div className='col-md-2'>
                    <button className='btn btn-primary mt-4'>Filer</button>
                </div>
            </div>
        </form>
    )
}
