const ConfigService = {
    apiUrl: function () {
        return process.env.REACT_APP_CONFIG_API_URL
    },
    allowedLanguages: function () {
        return ['en', 'pl', 'de', 'es', 'it', 'fr']
    },
    allowedTranslationTypes: function () {
        return ['schema category', 'schema name']
    },
    allowedBrands: function () {
        return ['ktm', 'gasgas', 'husky', 'yama']
    },
}

export default ConfigService
