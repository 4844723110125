import './App.css';
import 'react-notifications/lib/notifications.css'
import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./Component/Home";
import Header from "./Component/Header";
import Axios from "axios";
import ConfigService from "./ConfigService";
import {useImmerReducer} from "use-immer";
import {GlobalContext, initialState, reducer} from "./GlobalContext";
import {NotificationContainer, NotificationManager} from "react-notifications";
import Translations from "./Component/Translations";

Axios.defaults.baseURL = ConfigService.apiUrl();


function App() {
    const [state, dispatch] = useImmerReducer(reducer, initialState);

    useEffect(() => {
        localStorage.setItem('token', state.token)
    }, [state.token])

    useEffect(() => {
        if (state.flashMessage.type) {
            switch (state.flashMessage.type) {
                case 'success':
                    NotificationManager.success(state.flashMessage.text)
                    break;
                case 'error':
                    NotificationManager.error(state.flashMessage.text)
                    break;
                case 'info':
                    NotificationManager.info(state.flashMessage.text)
                    break;
                case 'warning':
                    NotificationManager.warning(state.flashMessage.text)
                    break
                default:
                    break;
            }
        }
    }, [state.flashMessage]);


    return (
        <GlobalContext.Provider value={ [state, dispatch] }>
            <Router>
                <div>
                    <Header/>
                    <NotificationContainer />
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route path="/translations">
                            <Translations />
                        </Route>

                    </Switch>
                </div>
            </Router>
        </GlobalContext.Provider>
    );
}

export default App;
