import React, {useContext, useEffect, useState} from 'react'
import TranslationContext from './TranslationContext'
import Axios from 'axios'
import {useHistory, useParams} from 'react-router-dom'
import GlobalContext from '../../GlobalContext'
import ConfigService from '../../ConfigService'

export default function TranslationEdit() {
    const history = useHistory()
    const [state] = useContext(TranslationContext)
    const [appState, appDispatch] = useContext(GlobalContext)
    const {id} = useParams()
    const [translation, setTranslation] = useState({})
    const [languageTranslations, setLanguageTranslations] = useState({})
    useEffect(() => {
        Axios.get('translation/' + id)
            .catch((e) => {
                appDispatch({
                    type: 'addFlashMessage',
                    data: {
                        type: 'error',
                        text: 'Error while fetching translations',
                    },
                })
            })
            .then((res) => {
                setTranslation(res.data)
                setLanguageTranslations(res.data.translations)
            })
    }, [])

    function handleSubmit(e) {
        e.preventDefault()
        const data = {}
        for (const lang in languageTranslations) {
            if (languageTranslations[lang] !== '') {
                data[lang] = languageTranslations[lang]
            }
        }
        Axios.put('/translation/' + id, data, {headers: {'Authorization': `Basic ${appState.token}`}})
            .catch((e) => {
                console.log(e)
                appDispatch({
                    type: 'addFlashMessage',
                    data: {
                        type: 'error',
                        text: 'Error while fetching translations try logout & login again to refresh token',
                    },
                })
            })
            .then((res) => {
                history.push('/translations')
            })
    }

    return (
        <div>
            <h1>Edit the translation</h1>
            {translation && (
                <form onSubmit={handleSubmit}>
                    <h2>Base value: {translation.value}</h2>
                    <input type='hidden' id='id' />
                    {ConfigService.allowedLanguages().map((language, index) => {
                        return (
                            <div key={index} className='form-group'>
                                <label htmlFor={language}>
                                    Translation to: {language}
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id={language}
                                    value={languageTranslations[language] || ''}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setLanguageTranslations({
                                            ...languageTranslations,
                                            [language]: e.target.value,
                                        })
                                        console.log(languageTranslations)
                                    }}
                                />
                            </div>
                        )
                    })}
                    <input className='btn btn-primary mt-3' type='submit' />
                </form>
            )}
        </div>
    )
}
