import React, {useContext, useState} from "react";
import Axios from "axios";
import GlobalContext from "../GlobalContext";

function Login() {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const [, appDispatch] = useContext(GlobalContext);

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('user', user);
        formData.append('password', password);

        Axios.post('/token', formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
        }).catch(error => {
        }).then(response => {
            if (response && response.data.token) {
                appDispatch({type: 'setToken', data: response.data})
                appDispatch({type: 'addFlashMessage', data: {type: 'success', text: 'You have been signed in'}})
            } else {
                appDispatch({type: 'addFlashMessage', data: {type: 'error', text: 'You provided incorrect login or password'}})
            }
        });
    }

    return(
        <div className="row mt-5">
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <div className="card border-secondary">
                    <div className="card-header">
                        Login

                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="user">Email address</label>
                                <input
                                    onChange={e => {setUser(e.target.value)}}
                                    value={user}
                                    type="text"
                                    className="form-control"
                                    id="user"
                                    placeholder="Enter username"
                                />

                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="password">Password</label>
                                <input
                                    onChange={e => {setPassword(e.target.value)}}
                                    value={password}
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Enter password" />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>

            </div>
            <div className="col-md-4"></div>

        </div>
    )
}

export default Login
